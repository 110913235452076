import React, { useEffect, useState, useCallback, useRef } from 'react';
import { db, auth, createUserWithEmailAndPassword } from '../firebase';
import { collection, query, where, getDocs, Timestamp, deleteDoc, doc, updateDoc, setDoc, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import EditModal from './EditModal';
import Papa from 'papaparse';
import Select from 'react-select';

Modal.setAppElement('#root');

function AdminPage() {
  const [reports, setReports] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [department, setDepartment] = useState('');
  const [agents, setAgents] = useState([]);
  const [agentMap, setAgentMap] = useState({}); // agentId to agentName map
  const [selectedAgent, setSelectedAgent] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isEnrolledOnly, setIsEnrolledOnly] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [transferSortOrder, setTransferSortOrder] = useState('asc');
  const [totalReports, setTotalReports] = useState(0);
  const [totalEnrolledAmount, setTotalEnrolledAmount] = useState(0);

  const navigate = useNavigate();
  const tableRef = useRef(null);

  // Fetch agents and create a map for quick lookup
  const fetchAgents = useCallback(async (department = '') => {
    try {
      let agentsQuery = query(collection(db, 'users'));

      if (department) {
        agentsQuery = query(agentsQuery, where('department', '==', department));
      }

      const agentsSnapshot = await getDocs(agentsQuery);
      const agentsData = agentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setAgents(agentsData);

      // Create a mapping from agentId to agentName
      const newAgentMap = {};
      agentsData.forEach(agent => {
        newAgentMap[agent.id] = `${agent.fname} ${agent.lname}`;
      });
      setAgentMap(newAgentMap); // Store this in state for quick lookup later
    } catch (error) {
      console.error("Error fetching agents: ", error);
    }
  }, []);

  useEffect(() => {
    const start = new Date();
    start.setHours(0, 0, 0, 0);

    const end = new Date();
    end.setHours(23, 59, 59, 999);

    setStartDate(start.toISOString().split('T')[0]);
    setEndDate(end.toISOString().split('T')[0]);

    fetchAgents();
    fetchReports(start.toISOString(), end.toISOString());
  }, [fetchAgents, refresh]);

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  useEffect(() => {
    if (!isValidDate(startDate) || !isValidDate(endDate)) {
      console.error('Invalid date values');
      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    const reportsQuery = query(
      collection(db, 'reports'),
      where('createdAt', '>=', Timestamp.fromDate(start)),
      where('createdAt', '<=', Timestamp.fromDate(end))
    );

    const unsubscribe = onSnapshot(reportsQuery, snapshot => {
      let reportsData = snapshot.docs.map(doc => {
        const report = { id: doc.id, ...doc.data() };
        report.agentName = agentMap[report.agentId] || 'Unknown'; // Apply agentMap
        return report;
      });

      reportsData = reportsData.sort((a, b) => {
        const dateA = a.createdAt.seconds * 1000;
        const dateB = b.createdAt.seconds * 1000;
        return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
      });

      setReports(reportsData);

      const totalReportsCount = reportsData.length;
      const totalEnrolledAmountSum = reportsData.reduce((total, report) => {
        const amount = report.enrolledAmount ? parseFloat(report.enrolledAmount.replace(/[^0-9.-]+/g, "")) : 0;
        return total + amount;
      }, 0);

      setTotalReports(totalReportsCount);
      setTotalEnrolledAmount(totalEnrolledAmountSum);
    });

    return () => unsubscribe();
  }, [startDate, endDate, department, selectedAgent, selectedTransfer, sortOrder, agentMap]);

  const fetchReports = async (startDate, endDate) => {
    if (!isValidDate(startDate) || !isValidDate(endDate)) {
      console.error('Invalid date values');
      return;
    }

    try {
      const start = new Date(startDate);
      const end = new Date(endDate);

      let reportsQuery = query(
        collection(db, 'reports'),
        where('createdAt', '>=', Timestamp.fromDate(start)),
        where('createdAt', '<=', Timestamp.fromDate(end))
      );

      if (department) {
        const agentsQuery = query(collection(db, 'users'), where('department', '==', department));
        const agentsSnapshot = await getDocs(agentsQuery);
        const agentIds = agentsSnapshot.docs.map(doc => doc.id);

        if (agentIds.length > 0) {
          reportsQuery = query(reportsQuery, where('agentId', 'in', agentIds));
        }
      }

      if (selectedAgent) {
        reportsQuery = query(reportsQuery, where('agentId', '==', selectedAgent));
      }

      if (isEnrolledOnly) {
        reportsQuery = query(reportsQuery, where('enrolled', '==', true));
      }

      if (selectedTransfer) {
        reportsQuery = query(reportsQuery, where('transfer', '==', selectedTransfer));
      }

      const querySnapshot = await getDocs(reportsQuery);
      let reportsData = querySnapshot.docs.map(doc => {
        const report = { id: doc.id, ...doc.data() };
        report.agentName = agentMap[report.agentId] || 'Unknown'; // Use agentMap to get agentName
        return report;
      });

      reportsData = reportsData.sort((a, b) => {
        const dateA = a.createdAt.seconds * 1000;
        const dateB = b.createdAt.seconds * 1000;
        const transferA = a.transfer;
        const transferB = b.transfer;

        if (sortOrder === 'asc') {
          return dateA - dateB;
        } else if (sortOrder === 'desc') {
          return dateB - dateA;
        } else if (transferSortOrder === 'asc') {
          return transferA.localeCompare(transferB);
        } else {
          return transferB.localeCompare(transferA);
        }
      });

      setReports(reportsData);

      const totalReportsCount = reportsData.length;
      const totalEnrolledAmountSum = reportsData.reduce((total, report) => {
        const amount = report.enrolledAmount ? parseFloat(report.enrolledAmount.replace(/[^0-9.-]+/g, "")) : 0;
        return total + amount;
      }, 0);

      setTotalReports(totalReportsCount);
      setTotalEnrolledAmount(totalEnrolledAmountSum);
    } catch (error) {
      console.error("Error fetching reports: ", error);
    }
  };

  const handleFilter = () => {
    fetchReports(startDate, endDate);
  };

  const handleDepartmentChange = async (e) => {
    const selectedDepartment = e.target.value;
    setDepartment(selectedDepartment);
    await fetchAgents(selectedDepartment);
    setSelectedAgent('');
  };

  const handleAgentChange = (e) => {
    const selectedAgentId = e.target.value;
    setSelectedAgent(selectedAgentId);
    if (!department) {
      fetchAgents();
    }
    if (selectedAgentId) {
      fetchReports(startDate, endDate);
    }
  };

  const handleTransferChange = (e) => {
    const selectedTransfer = e.target.value;
    setSelectedTransfer(selectedTransfer);
    fetchReports(startDate, endDate);
  };

  const handleEnrolledChange = (e) => {
    setIsEnrolledOnly(e.target.checked);
    fetchReports(startDate, endDate);
  };

  const handleDelete = async () => {
    if (selectedReport) {
      try {
        await deleteDoc(doc(db, 'reports', selectedReport.id));
        setIsDeleteModalOpen(false);
        setSelectedReport(null);
        fetchReports(startDate, endDate);
      } catch (error) {
        console.error("Error deleting report: ", error);
      }
    }
  };

  const handleEdit = async (updatedReport) => {
    if (selectedReport) {
      try {
        await updateDoc(doc(db, 'reports', selectedReport.id), updatedReport);
        setIsEditModalOpen(false);
        setSelectedReport(null);
        setRefresh(prev => !prev);
      } catch (error) {
        console.error("Error updating report: ", error);
      }
    }
  };

  const openDeleteModal = (report) => {
    setSelectedReport(report);
    setIsDeleteModalOpen(true);
  };

  const openEditModal = (report) => {
    setSelectedReport(report);
    setIsEditModalOpen(true);
  };

  const closeModals = () => {
    setIsDeleteModalOpen(false);
    setIsEditModalOpen(false);
    setSelectedReport(null);
  };

  const handleCreateUser = async (email, password, fname, lname, department) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const { user } = userCredential;

      await setDoc(doc(db, 'users', user.uid), {
        createdAt: new Date(),
        department,
        email,
        fname,
        lname,
        role: 'agent',
      });

      console.log('User created successfully:', user);
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  const getDurationColor = (duration) => {
    const minutes = parseInt(duration.split(':')[0]) * 60 + parseInt(duration.split(':')[1]);
    if (minutes < 2) {
      return 'yellow';
    } else if (minutes > 20 && minutes <= 60) {
      return 'cyan';
    } else if (minutes > 60) {
      return '#2afe00';
    } else {
      return 'inherit';
    }
  };

  const handleSearchToggle = () => {
    setIsSearchOpen(prev => !prev);
    if (isSearchOpen) {
      setSearchQuery('');
      fetchReports(startDate, endDate);
    }
  };

  const handleSearch = async () => {
    if (!searchQuery) return;

    const reportsRef = collection(db, 'reports');
    const searchQueryLower = searchQuery.toLowerCase();

    try {
      const reportsSnapshot = await getDocs(reportsRef);
      const filteredReports = reportsSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(report =>
          Object.values(report).some(value =>
            value && value.toString().toLowerCase().includes(searchQueryLower)
          )
        )
        .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

      setReports(filteredReports);
    } catch (error) {
      console.error("Error searching reports: ", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    fetchReports(startDate, endDate);
  };

  const toggleTransferSortOrder = () => {
    setTransferSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    fetchReports(startDate, endDate);
  };

  const exportToCSV = () => {
    const csvData = reports.map(report => ({
      Date: new Date(report.createdAt.seconds * 1000).toLocaleDateString(),
      Agent: report.agentName,
      Transfer: report.transfer,
      'Access Code': report.accessCode,
      'Phone #': report.phone,
      Name: report.name,
      'Start Time': report.startTime,
      Duration: report.duration,
      Notes: report.notes,
      Enrolled: report.enrolled ? 'Yes' : 'No',
      'Enrolled Amount': report.enrolledAmount,
      'Not Enough Debt': report.notEnoughDebt ? 'Yes' : 'No',
      'State Liability': report.stateLiability ? 'Yes' : 'No',
      'Federal Liability': report.federalLiability ? 'Yes' : 'No'
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'reports.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (fullScreen) {
      const interval = setInterval(() => {
        setRefresh(prev => !prev);
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [fullScreen]);

  useEffect(() => {
    if (fullScreen && tableRef.current) {
      tableRef.current.scrollTop = tableRef.current.scrollHeight;
    }
  }, [fullScreen, reports]);

  const fullScreenStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowY: 'auto'
  };

  const exitFullScreen = () => {
    setFullScreen(false);
  };

  return (
    <div className={`adminpage ${fullScreen ? 'fullscreen adminpage-fullscreen' : ''}`} style={fullScreen ? fullScreenStyle : {}}>
      <div className={`header ${fullScreen ? 'sticky-header' : ''}`}>
        <h1 className="page-title">Daily Report</h1>
        {fullScreen && (
          <button className="exit-button" onClick={exitFullScreen}>Exit Full Screen</button>
        )}
      </div>
      {!fullScreen && (
        <div className={`filter-container ${fullScreen ? 'sticky-header' : ''}`}>
          <label>Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <label>End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <label>Department:</label>
          <select value={department} onChange={handleDepartmentChange}>
            <option value="">All</option>
            <option value="debt">Debt</option>
            <option value="tax">Tax</option>
          </select>
          <label>Agent:</label>
          <select value={selectedAgent} onChange={handleAgentChange}>
            <option value="">All</option>
            {agents.map((agent) => (
              <option key={agent.id} value={agent.id}>{`${agent.fname} ${agent.lname}`}</option>
            ))}
          </select>
          <label>Transfer:</label>
          <select value={selectedTransfer} onChange={handleTransferChange}>
            <option value="">All</option>
            {[...Array(55).keys()].map(i => (
              <option key={i + 1} value={i + 1}>{i + 1}</option>
            ))}
            <option value="CPD">CPD</option>
            <option value="FB-1">FB-1</option>
            <option value="FB-2">FB-2</option>
            <option value="FB-3">FB-3</option>
            <option value="FB-4">FB-4</option>
            <option value="FB-5">FB-5</option>
            <option value="GA-1">GA-1</option>
            <option value="GA-2">GA-2</option>
            <option value="GA-3">GA-3</option>
            <option value="GA-4">GA-4</option>
            <option value="GA-5">GA-5</option>
            <option value="IG-1">IG-1</option>
            <option value="IG-2">IG-2</option>
            <option value="IG-3">IG-3</option>
            <option value="TK-1">TK-1</option>
            <option value="TK-2">TK-2</option>
            <option value="TK-3">TK-3</option>
            <option value="YT-1">YT-1</option>
            <option value="YT-2">YT-2</option>
            <option value="YT-3">YT-3</option>
            <option value="T-1">T-1</option>
            <option value="T-2">T-2</option>
            <option value="T-3">T-3</option>
            <option value="T-4">T-4</option>
            <option value="T-5">T-5</option>
            <option value="CPT">CPT</option>
          </select>
          <label>
            Enrolled:
            <input
              type="checkbox"
              checked={isEnrolledOnly}
              onChange={handleEnrolledChange}
            />
          </label>
          <button className="filter-button" onClick={handleFilter}>Filter</button>
          <button onClick={() => navigate('/admin/enrollments')}>Enrollments</button>
          <button onClick={() => navigate('/leaderboard')}>Leaderboard</button>
          <button onClick={() => setFullScreen(!fullScreen)}>Toggle Full Screen</button>
          <button onClick={() => navigate('/users')}>Manage Users</button>
          <button onClick={exportToCSV}>Export to CSV</button>
          <button onClick={handleSearchToggle}>{isSearchOpen ? 'Close' : 'Search'}</button>
          {isSearchOpen && (
            <div className="search-container">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Enter search query..."
              />
              <button onClick={handleSearch}>Search</button>
            </div>
          )}
        </div>
      )}
      <div className="table-container" ref={tableRef}>
        <table>
          <thead>
            <tr>
              <th onClick={toggleSortOrder} style={{ cursor: 'pointer' }}>
                Date {sortOrder === 'asc' ? '↑' : '↓'}
              </th>
              <th>Agent</th>
              <th onClick={toggleTransferSortOrder} style={{ cursor: 'pointer' }}>
                TSFR # {transferSortOrder === 'asc' ? '↑' : '↓'}
              </th>
              <th>Access Code</th>
              <th>Phone #</th>
              <th>Name</th>
              <th>Start Time</th>
              <th>Duration</th>
              <th>Notes</th>
              <th>Enrolled</th>
              <th>Enrolled Amount</th>
              <th>Not Enough Debt</th>
              {!fullScreen || department !== 'debt' ? <th>State Liability</th> : null}
              {!fullScreen || department !== 'debt' ? <th>Federal Liability</th> : null}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report) => (
              <tr key={report.id}>
                <td>{new Date(report.createdAt.seconds * 1000).toLocaleDateString()}</td>
                <td>{report.agentName}</td>
                <td>{report.transfer}</td>
                <td>{report.accessCode}</td>
                <td>{report.phone}</td>
                <td>{report.name}</td>
                <td>{report.startTime}</td>
                <td className="duration-cell" style={{ backgroundColor: getDurationColor(report.duration) }}>{report.duration}</td>
                <td>{report.notes}</td>
                <td>{report.enrolled ? 'Yes' : 'No'}</td>
                <td>{report.enrolledAmount}</td>
                <td>{report.notEnoughDebt ? 'Yes' : 'No'}</td>
                {!fullScreen || department !== 'debt' ? <td>{report.stateLiability ? 'Yes' : 'No'}</td> : null}
                {!fullScreen || department !== 'debt' ? <td>{report.federalLiability ? 'Yes' : 'No'}</td> : null}
                <td>
                  <button className="edit-button" onClick={() => openEditModal(report)}>Edit</button>
                  <button className="delete-button" onClick={() => openDeleteModal(report)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className="footer-row">
            <tr className="dailyFooter">
              <td colSpan="9">Report Count: {totalReports}</td>
              <td>Total</td>
              <td>${totalEnrolledAmount.toFixed(2)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeModals}
        contentLabel="Delete Confirmation"
      >
        <h2>Delete Confirmation</h2>
        <p>Are you sure you want to delete this report?</p>
        <button onClick={handleDelete}>Delete</button>
        <button onClick={closeModals}>Cancel</button>
      </Modal>
      {isEditModalOpen && (
        <EditModal
          isOpen={isEditModalOpen}
          onClose={closeModals}
          report={selectedReport}
          onSave={handleEdit}
        />
      )}
    </div>
  );
}

export default AdminPage;
