import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc, deleteDoc, doc, updateDoc, onSnapshot, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

function TaxEnrollmentPage() {
  const [enrollments, setEnrollments] = useState([]);
  const [newEnrollmentData, setNewEnrollmentData] = useState({
    cancellationAmount: '0',
    cancellationCount: '0',
    dayAmounts: [],
    dayCount: '0',
    email: '',
    fname: '',
    lname: '',
    monthAmount: '0',
    monthCount: '0',
    pendingAmount: '0',
    pendingAmounts: [], // Added for multiple pending amounts
    pendingCount: '0',
    quarterAmount: '0',
    weekAmount: '0',
    weekCount: '0'
  });
  const [editEnrollmentData, setEditEnrollmentData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [fullscreen, setFullscreen] = useState(localStorage.getItem('fullscreen') === 'true');
  const navigate = useNavigate();

  useEffect(() => {
    const enrollmentsRef = collection(db, 'taxEnrollments');
    const unsubscribe = onSnapshot(enrollmentsRef, (snapshot) => {
      let enrollmentsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      enrollmentsData = enrollmentsData.map(enrollment => ({
        ...enrollment,
        dayAmounts: Array.isArray(enrollment.dayAmounts) ? enrollment.dayAmounts : [],
        pendingAmounts: Array.isArray(enrollment.pendingAmounts) ? enrollment.pendingAmounts : [] // Ensure pendingAmounts is an array
      }));

      enrollmentsData.sort((a, b) => {
        const aDayAmountsTotal = a.dayAmounts.reduce((sum, val) => sum + parseFloat(val), 0);
        const bDayAmountsTotal = b.dayAmounts.reduce((sum, val) => sum + parseFloat(val), 0);
        if (a.dayCount !== b.dayCount) {
          return b.dayCount - a.dayCount;
        } else if (aDayAmountsTotal !== bDayAmountsTotal) {
          return bDayAmountsTotal - aDayAmountsTotal;
        } else if (a.weekCount !== b.weekCount) {
          return b.weekCount - a.weekCount;
        } else if (a.weekAmount !== b.weekAmount) {
          return b.weekAmount - a.weekAmount;
        } else if (a.monthCount !== b.monthCount) {
          return b.monthCount - a.monthCount;
        } else if (a.monthAmount !== b.monthAmount) {
          return b.monthAmount - a.monthAmount;
        } else {
          return b.quarterAmount - a.quarterAmount;
        }
      });

      setEnrollments(enrollmentsData);
    });

    return () => unsubscribe();
  }, []);

  const handleAddRow = async () => {
    try {
      await addDoc(collection(db, 'taxEnrollments'), newEnrollmentData);
      setNewEnrollmentData({
        cancellationAmount: '0',
        cancellationCount: '0',
        dayAmounts: [],
        dayCount: '0',
        email: '',
        fname: '',
        lname: '',
        monthAmount: '0',
        monthCount: '0',
        pendingAmount: '0',
        pendingAmounts: [], // Reset pendingAmounts
        pendingCount: '0',
        quarterAmount: '0',
        weekAmount: '0',
        weekCount: '0'
      });
    } catch (error) {
      console.error('Error adding enrollment: ', error);
    }
  };

  const openDeleteModal = (id) => {
    setDeleteId(id);
  };

  const handleDeleteRow = async () => {
    if (deleteId) {
      try {
        await deleteDoc(doc(db, 'taxEnrollments', deleteId));
        setDeleteId(null);
      } catch (error) {
        console.error('Error deleting enrollment: ', error);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditEnrollmentData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handlePendingCountChange = (e) => {
    const { value } = e.target;
    const newPendingCount = Math.max(0, parseInt(value) || 0);
    const oldPendingCount = parseInt(editEnrollmentData.pendingCount) || 0;
    const pendingAmounts = [...editEnrollmentData.pendingAmounts];

    if (newPendingCount < oldPendingCount) {
      const removedAmounts = pendingAmounts.slice(newPendingCount).reduce((total, amount) => total + parseFloat(amount || 0), 0);
      setEditEnrollmentData(prevData => ({
        ...prevData,
        pendingCount: newPendingCount.toString(),
        pendingAmounts: pendingAmounts.slice(0, newPendingCount),
        pendingAmount: Math.max(0, (parseFloat(prevData.pendingAmount || 0) - removedAmounts)).toString(),
      }));
    } else {
      const newPendingAmounts = Array(newPendingCount).fill('0');
      for (let i = 0; i < Math.min(pendingAmounts.length, newPendingCount); i++) {
        newPendingAmounts[i] = pendingAmounts[i];
      }
      setEditEnrollmentData(prevData => ({
        ...prevData,
        pendingCount: newPendingCount.toString(),
        pendingAmounts: newPendingAmounts
      }));
    }
  };

  const handlePendingAmountChange = (index, value) => {
    const newPendingAmounts = [...editEnrollmentData.pendingAmounts];
    const previousValue = parseFloat(newPendingAmounts[index]) || 0;
    newPendingAmounts[index] = value;
    const pendingTotal = newPendingAmounts.reduce((total, amount) => total + parseFloat(amount || 0), 0);
    setEditEnrollmentData(prevData => ({
      ...prevData,
      pendingAmounts: newPendingAmounts,
      pendingAmount: pendingTotal.toString(),
    }));
  };

  const handleDayCountChange = (e) => {
    const { value } = e.target;
    const newDayCount = Math.max(0, parseInt(value) || 0);
    const oldDayCount = parseInt(editEnrollmentData.dayCount) || 0;
    const dayAmounts = [...editEnrollmentData.dayAmounts];

    if (newDayCount < oldDayCount) {
      const removedAmounts = dayAmounts.slice(newDayCount).reduce((total, amount) => total + parseFloat(amount || 0), 0);
      setEditEnrollmentData(prevData => ({
        ...prevData,
        dayCount: newDayCount.toString(),
        dayAmounts: dayAmounts.slice(0, newDayCount),
        weekCount: prevData.weekCount,
        weekAmount: Math.max(0, (parseFloat(prevData.weekAmount || 0) - removedAmounts)).toString(),
        monthCount: prevData.monthCount,
        monthAmount: Math.max(0, (parseFloat(prevData.monthAmount || 0) - removedAmounts)).toString(),
        quarterAmount: Math.max(0, (parseFloat(prevData.quarterAmount || 0) - removedAmounts)).toString(),
      }));
    } else {
      const newDayAmounts = Array(newDayCount).fill('0');
      for (let i = 0; i < Math.min(dayAmounts.length, newDayCount); i++) {
        newDayAmounts[i] = dayAmounts[i];
      }
      setEditEnrollmentData(prevData => ({
        ...prevData,
        dayCount: newDayCount.toString(),
        dayAmounts: newDayAmounts
      }));
    }
  };

  const handleDayAmountChange = (index, value) => {
    const newDayAmounts = [...editEnrollmentData.dayAmounts];
    const previousValue = parseFloat(newDayAmounts[index]) || 0;
    newDayAmounts[index] = value;
    const dayTotal = newDayAmounts.reduce((total, amount) => total + parseFloat(amount || 0), 0);
    setEditEnrollmentData(prevData => ({
      ...prevData,
      dayAmounts: newDayAmounts,
      dayAmount: dayTotal.toString(),
      weekAmount: Math.max(0, (parseFloat(prevData.weekAmount || 0) + parseFloat(value || 0) - previousValue)).toString(),
      monthAmount: Math.max(0, (parseFloat(prevData.monthAmount || 0) + parseFloat(value || 0) - previousValue)).toString(),
      quarterAmount: Math.max(0, (parseFloat(prevData.quarterAmount || 0) + parseFloat(value || 0) - previousValue)).toString(),
    }));
  };

  const handleEditRow = (enrollment) => {
    setEditId(enrollment.id);
    setIsEditing(true);
    setEditEnrollmentData({
      ...enrollment,
      dayAmounts: enrollment.dayAmounts || [],
      pendingAmounts: enrollment.pendingAmounts || []
    });
  };

  const handleSaveEdit = async () => {
    try {
      if (!editId) {
        throw new Error('No enrollment ID is set for editing.');
      }

      const enrollmentRef = doc(db, 'taxEnrollments', editId);
      const enrollmentSnapshot = await getDoc(enrollmentRef);

      if (!enrollmentSnapshot.exists()) {
        throw new Error('Enrollment does not exist.');
      }

      const enrollmentData = enrollmentSnapshot.data();

      // Calculate the new weekCount and monthCount by adding the dayCount
      const originalDayCount = parseInt(enrollmentData.dayCount) || 0;
      const newDayCount = parseInt(editEnrollmentData.dayCount) || 0;

      const originalWeekCount = parseInt(enrollmentData.weekCount) || 0;
      const originalMonthCount = parseInt(enrollmentData.monthCount) || 0;

      // Update the weekCount and monthCount based on the change in dayCount
      const updatedWeekCount = originalWeekCount - originalDayCount + newDayCount;
      const updatedMonthCount = originalMonthCount - originalDayCount + newDayCount;

      const updatedData = {
        ...editEnrollmentData,
        weekCount: updatedWeekCount.toString(),
        monthCount: updatedMonthCount.toString(),
      };

      await updateDoc(enrollmentRef, updatedData);

      setEditId(null);
      setIsEditing(false);
      setEditEnrollmentData(null);
    } catch (error) {
      console.error('Error updating enrollment: ', error);
    }
  };

  const handleCancelEdit = () => {
    setEditId(null);
    setIsEditing(false);
    setEditEnrollmentData(null);
  };

  const toggleFullScreen = () => {
    const newFullscreenState = !fullscreen;
    setFullscreen(newFullscreenState);
    localStorage.setItem('fullscreen', newFullscreenState);
  };

  const exitFullScreen = () => {
    setFullscreen(false);
    localStorage.setItem('fullscreen', false);
  };

  const formatNumber = (num) => {
    if (Number.isNaN(num) || num === undefined) return '0.00';
    return parseFloat(num).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const totals = {
    cancellationAmount: enrollments.reduce((total, enrollment) => total + (parseFloat(enrollment.cancellationAmount) || 0), 0),
    cancellationCount: enrollments.reduce((total, enrollment) => total + (parseInt(enrollment.cancellationCount) || 0), 0),
    dayCount: enrollments.reduce((total, enrollment) => total + (parseInt(enrollment.dayCount) || 0), 0),
    dayAmount: enrollments.reduce((total, enrollment) => total + (parseFloat(enrollment.dayAmounts?.reduce((sum, amount) => sum + parseFloat(amount || 0), 0) || 0)), 0),
    pendingCount: enrollments.reduce((total, enrollment) => total + (parseInt(enrollment.pendingCount) || 0), 0),
    pendingAmount: enrollments.reduce((total, enrollment) => total + (parseFloat(enrollment.pendingAmounts?.reduce((sum, amount) => sum + parseFloat(amount || 0), 0) || 0)), 0),
    weekCount: enrollments.reduce((total, enrollment) => total + (parseInt(enrollment.weekCount) || 0), 0),
    weekAmount: enrollments.reduce((total, enrollment) => total + (parseFloat(enrollment.weekAmount) || 0), 0),
    monthCount: enrollments.reduce((total, enrollment) => total + (parseInt(enrollment.monthCount) || 0), 0),
    monthAmount: enrollments.reduce((total, enrollment) => total + (parseFloat(enrollment.monthAmount) || 0), 0),
    quarterAmount: enrollments.reduce((total, enrollment) => total + (parseFloat(enrollment.quarterAmount) || 0), 0)
  };

  return (
    <div className={fullscreen ? 'fullscreen' : ''}>
      {!fullscreen && (
        <>
          <button onClick={() => navigate('/admin')}>Back To Reports</button>
          <button onClick={() => navigate('/taxleaderboard')}>Tax Leaderboard</button>
          <button onClick={() => navigate('/admin/enrollments')}>Debt Enrollments</button>
          <button onClick={toggleFullScreen}>Full Screen</button>
        </>
      )}
      {fullscreen && (
        <button className="exit-button" onClick={exitFullScreen}>Exit Full Screen</button>
      )}
      <div className={`enrollment-container ${fullscreen ? 'fullscreen enrollment-fullscreen' : ''}`}>
        <table>
          <thead>
            <tr>
              <th className="cancellationAmount">Cancellation $</th>
              <th className="cancellationCount">Cancellation #</th>
              <th className="agent">Agent</th>
              <th className="pendingCount">Pending #</th>
              <th className="pendingAmount">Pending $</th>
              <th className="dayCount">Day #</th>
              <th className="dayAmount">Day $</th>
              <th className="weekCount">Week #</th>
              <th className="weekAmount">Week $</th>
              <th className="monthCount">Month #</th>
              <th className="monthAmount">Month $</th>
              <th className="quarterAmount">Quarter $</th>
              {!fullscreen && <th className="actions">Actions</th>}
            </tr>
          </thead>
          <tbody>
            {enrollments.map(enrollment => (
              <React.Fragment key={enrollment.id}>
                {editId === enrollment.id ? (
                  <tr>
                    <td><input type="text" name="cancellationAmount" value={editEnrollmentData.cancellationAmount} onChange={handleChange} /></td>
                    <td><input type="text" name="cancellationCount" value={editEnrollmentData.cancellationCount} onChange={handleChange} /></td>
                    <td>
                      <input type="text" name="fname" value={editEnrollmentData.fname} onChange={handleChange} placeholder="First Name" />
                      <input type="text" name="lname" value={editEnrollmentData.lname} onChange={handleChange} placeholder="Last Name" />
                    </td>
                    <td><input type="number" name="pendingCount" value={editEnrollmentData.pendingCount} onChange={handlePendingCountChange} /></td>
                    <td>
                      {editEnrollmentData.pendingCount > 0 && editEnrollmentData.pendingAmounts.map((amount, index) => (
                        <input
                          key={index}
                          type="number"
                          value={amount}
                          onChange={(e) => handlePendingAmountChange(index, e.target.value)}
                        />
                      ))}
                    </td>
                    <td><input type="number" name="dayCount" value={editEnrollmentData.dayCount} onChange={handleDayCountChange} /></td>
                    <td>
                      {editEnrollmentData.dayCount > 0 && editEnrollmentData.dayAmounts.map((amount, index) => (
                        <input
                          key={index}
                          type="number"
                          value={amount}
                          onChange={(e) => handleDayAmountChange(index, e.target.value)}
                        />
                      ))}
                    </td>
                    <td><input type="number" name="weekCount" value={editEnrollmentData.weekCount} onChange={handleChange} /></td>
                    <td>
                      {editEnrollmentData.weekCount > 0 && (
                        <input
                          type="number"
                          name="weekAmount"
                          value={editEnrollmentData.weekAmount}
                          onChange={handleChange}
                        />
                      )}
                    </td>
                    <td><input type="number" name="monthCount" value={editEnrollmentData.monthCount} onChange={handleChange} /></td>
                    <td>
                      {editEnrollmentData.monthCount > 0 && (
                        <input
                          type="number"
                          name="monthAmount"
                          value={editEnrollmentData.monthAmount}
                          onChange={handleChange}
                        />
                      )}
                    </td>
                    <td><input type="text" name="quarterAmount" value={editEnrollmentData.quarterAmount} onChange={handleChange} /></td>
                    {!fullscreen && (
                      <td className="actions">
                        <button onClick={handleSaveEdit}>Save</button>
                        <button onClick={handleCancelEdit}>Cancel</button>
                      </td>
                    )}
                  </tr>
                ) : (
                  <tr>
                    <td className="cancellationAmount">{formatNumber(enrollment.cancellationAmount)}</td>
                    <td className="cancellationCount">{enrollment.cancellationCount}</td>
                    <td className="agent">{enrollment.fname} {enrollment.lname}</td>
                    <td className="pendingCount">{enrollment.pendingCount}</td>
                    <td className="pendingAmount">{formatNumber(enrollment.pendingAmounts?.reduce((sum, amount) => sum + parseFloat(amount || 0), 0) || 0)}</td>
                    <td className="dayCount">{enrollment.dayCount}</td>
                    <td className="dayAmount">{formatNumber(enrollment.dayAmounts?.reduce((sum, amount) => sum + parseFloat(amount || 0), 0) || 0)}</td>
                    <td className="weekCount">{enrollment.weekCount}</td>
                    <td className="weekAmount">{formatNumber(enrollment.weekAmount)}</td>
                    <td className="monthCount">{enrollment.monthCount}</td>
                    <td className="monthAmount">{formatNumber(enrollment.monthAmount)}</td>
                    <td className="quarterAmount">{formatNumber(enrollment.quarterAmount)}</td>
                    {!fullscreen && (
                      <td className="actions">
                        <button onClick={() => handleEditRow(enrollment)}>Edit</button>
                        <button
                          onClick={() => openDeleteModal(enrollment.id)}
                          className="delete-button"
                          disabled={deleteId === enrollment.id}
                        >
                          Delete
                        </button>
                      </td>
                    )}
                  </tr>
                )}
              </React.Fragment>
            ))}
            {deleteId && (
              <tr>
                <td colSpan="13">
                  <div>
                    <h2>Are you sure you want to delete? This action cannot be undone.</h2>
                    <button onClick={handleDeleteRow}>Yes</button>
                    <button onClick={() => setDeleteId(null)}>No</button>
                  </div>
                </td>
              </tr>
            )}
            <tr className="enrollment-total-row">
              <td className="enrollment-total-td">${formatNumber(totals.cancellationAmount)}</td>
              <td className="enrollment-total-td">{totals.cancellationCount}</td>
              <td className="enrollment-total-td">TOTALS</td>
              <td className="enrollment-total-td">{totals.pendingCount}</td>
              <td className="enrollment-total-td">${formatNumber(totals.pendingAmount)}</td>
              <td className="enrollment-total-td">{totals.dayCount}</td>
              <td className="enrollment-total-td">${formatNumber(totals.dayAmount)}</td>
              <td className="enrollment-total-td">{totals.weekCount}</td>
              <td className="enrollment-total-td">${formatNumber(totals.weekAmount)}</td>
              <td className="enrollment-total-td">{totals.monthCount}</td>
              <td className="enrollment-total-td">${formatNumber(totals.monthAmount)}</td>
              <td className="enrollment-total-td">${formatNumber(totals.quarterAmount)}</td>
              {!fullscreen && (
                <td className="actions">
                  <button onClick={handleSaveEdit}>Save</button>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TaxEnrollmentPage;
