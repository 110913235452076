import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { db, auth } from '../firebase';
import { getDoc, doc, updateDoc, onSnapshot } from 'firebase/firestore';

Modal.setAppElement('#root'); // Adjust this according to your root element

function EditModal({ isOpen, onClose, report, onSave }) {
  const [formData, setFormData] = useState({
    accessCode: '',
    accessPinCode: '',
    agentId: '',
    createdAt: '',
    dob: '',
    duration: '',
    email: '',
    enrolled: false,
    enrolledAmount: '',
    federalLiability: '',
    id: '',
    lead: '',
    name: '',
    notEnoughDebt: false,
    notes: '',
    phone: '',
    phoneSuffix: '',
    ssn: '',
    startTime: '',
    stateLiability: '',
    transfer: ''
  });

  const [userDepartment, setUserDepartment] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [reportDepartment, setReportDepartment] = useState('');

  useEffect(() => {
    const fetchUserDetails = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserDepartment(userDoc.data().department);
          setIsAdmin(userDoc.data().role === 'admin');
        }
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    const fetchReportDetails = async () => {
      if (report) {
        console.log("Report ID: ", report.id); // Log the report ID
        const agentDoc = await getDoc(doc(db, 'users', report.agentId));
        if (agentDoc.exists()) {
          setReportDepartment(agentDoc.data().department);
        }

        setFormData({
          accessCode: report.accessCode || '',
          accessPinCode: report.accessPinCode || '',
          agentId: report.agentId || '',
          createdAt: report.createdAt || '',
          dob: report.dob || '',
          duration: report.duration || '',
          email: report.email || '',
          enrolled: report.enrolled || false,
          enrolledAmount: report.enrolledAmount || '',
          federalLiability: report.federalLiability || '',
          id: report.id || '',
          lead: report.lead || '',
          name: report.name || '',
          notEnoughDebt: report.notEnoughDebt || false,
          notes: report.notes || '',
          phone: report.phone || '',
          phoneSuffix: report.phoneSuffix || '',
          ssn: report.ssn || '',
          startTime: report.startTime || '',
          stateLiability: report.stateLiability || '',
          transfer: report.transfer || ''
        });
      }
    };

    fetchReportDetails();
  }, [report]);

  useEffect(() => {
    if (report && report.id) {
      const unsubscribe = onSnapshot(doc(db, 'reports', report.id), (doc) => {
        if (doc.exists()) {
          setFormData({
            ...doc.data(),
            id: doc.id // Ensure the id is set in formData
          });
        }
      });

      return () => unsubscribe();
    }
  }, [report]);

  const generateAccessPinCode = () => {
    return Math.floor(100000 + Math.random() * 900000).toString(); // Generate 6-digit PIN
  };

  const formatDuration = (value) => {
    const cleanedValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    const formattedValue = cleanedValue.match(/.{1,2}/g)?.join(':') || ''; // Insert colons between each group of 2 digits
    return formattedValue;
  };

  const formatStartTime = (value) => {
    const cleanedValue = value.replace(/[^0-9apAP]/g, '').toUpperCase(); // Remove non-numeric and non-am/pm characters
    let timePart = cleanedValue.slice(0, 4); // First 4 characters are the time part
    let periodPart = cleanedValue.slice(4); // Remaining characters are the period part

    // Insert colon between the first two and second two digits
    if (timePart.length >= 3) {
      timePart = timePart.slice(0, 2) + ':' + timePart.slice(2, 4);
    }

    // Ensure only one set of AM/PM is allowed
    if (periodPart.includes('A') || periodPart.includes('P')) {
      if (periodPart.includes('A')) {
        periodPart = 'AM';
      } else if (periodPart.includes('P')) {
        periodPart = 'PM';
      }
    } else {
      periodPart = '';
    }

    return timePart + (periodPart ? ' ' + periodPart : '');
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    let updatedValue = value;

    if (name === 'phone') {
      // Format phone number to digits only
      updatedValue = value.replace(/\D/g, '').slice(0, 10); // Replace non-digits and limit to 10 characters
    }

    if (name === 'duration') {
      // Format duration input to HH:MM:SS
      updatedValue = formatDuration(value);
    }

    if (name === 'startTime') {
      // Format start time input to HH:MM AM/PM
      updatedValue = formatStartTime(value);
    }

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : updatedValue
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onSave(formData);
    onClose(); // Close the modal after saving
  };

  const handleRequestClose = () => {
    setFormData({ /* Reset form data here */ });
    onClose();
  };

  const handleAfterClose = () => {
    setFormData({ /* Reset form data here */ });
  };

  const handleCreateLink = async () => {
    if (!formData.id) {
      console.error('Report ID is missing');
      return;
    }

    const accessPinCode = generateAccessPinCode(); // Generate access PIN code
    const formattedPhone = `+1${formData.phone}`;
    const smsMessage = `Please follow the link for secured verification: ${window.location.origin}/clientinput?reportId=${formData.id} . If you did not request this link to update your application please ignore.`;

    console.log("Sending WhatsApp message with parameters:", { phone: formattedPhone, message: smsMessage, reportId: formData.id });

    try {
      // Save the access PIN code in the report document
      await updateDoc(doc(db, 'reports', formData.id), { accessPinCode });

      // Update the form data to display the generated PIN code
      setFormData({
        ...formData,
        accessPinCode: accessPinCode
      });

      const response = await fetch('https://us-central1-agents-report-c94b4.cloudfunctions.net/sendMessage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          phone: formattedPhone,
          message: smsMessage
        })
      });

      if (response.ok) {
        console.log('Message sent successfully');
      } else {
        console.error('Error sending message:', response.statusText);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleSendEmail = async () => {
    if (!formData.id || !formData.email) {
      console.error('Report ID or email is missing');
      return;
    }

    const accessPinCode = generateAccessPinCode(); // Generate access PIN code
    const emailMessage = `Please follow the link for secured verification: ${window.location.origin}/clientinput?reportId=${formData.id}`;

    console.log("Preparing to send email with parameters:", { email: formData.email, message: emailMessage, reportId: formData.id });

    try {
      // Save the access PIN code in the report document
      await updateDoc(doc(db, 'reports', formData.id), { accessPinCode });

      // Update the form data to display the generated PIN code
      setFormData({
        ...formData,
        accessPinCode: accessPinCode
      });

      // Send the email by making a POST request to the Firebase function
      const response = await fetch('https://us-central1-your-project-id.cloudfunctions.net/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          to: formData.email,
          subject: 'Secure Verification Link',
          text: emailMessage,
          html: `<p>${emailMessage}</p>`
        })
      });

      if (response.ok) {
        console.log('Email sent successfully');
      } else {
        console.error('Error sending email:', response.statusText);
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleRequestClose} onAfterClose={handleAfterClose} className="ModalOverlay">
      <form onSubmit={handleSubmit} className="modal-form">
        <div>
          <label>Transfer</label>
          <select name="transfer" value={formData.transfer} onChange={handleChange}>
            {isAdmin ? (
              <>
                {[...Array(55).keys()].map(i => (
                  <option key={i + 1} value={i + 1}>{i + 1}</option>
                ))}
                <option value="CPD">CPD</option>
                <option value="IAT">IAT</option>
                <option value="HUB">HUB</option>
                <option value="FB-1">FB-1</option>
                <option value="FB-2">FB-2</option>
                <option value="FB-3">FB-3</option>
                <option value="FB-4">FB-4</option>
                <option value="FB-5">FB-5</option>
                <option value="GA-1">GA-1</option>
                <option value="GA-2">GA-2</option>
                <option value="GA-3">GA-3</option>
                <option value="GA-4">GA-4</option>
                <option value="GA-5">GA-5</option>
                <option value="IG-1">IG-1</option>
                <option value="IG-2">IG-2</option>
                <option value="IG-3">IG-3</option>
                <option value="TK-1">TK-1</option>
                <option value="TK-2">TK-2</option>
                <option value="TK-3">TK-3</option>
                <option value="YT-1">YT-1</option>
                <option value="YT-2">YT-2</option>
                <option value="YT-3">YT-3</option>
                <option value="T-1">T-1</option>
                <option value="T-2">T-2</option>
                <option value="T-3">T-3</option>
                <option value="T-4">T-4</option>
                <option value="T-5">T-5</option>
                <option value="T-6">T-6</option>
                <option value="CPT">CPT</option>
              </>
            ) : (
              reportDepartment === 'tax' ? (
                <>
                  <option value="T-1">T-1</option>
                  <option value="T-2">T-2</option>
                  <option value="T-3">T-3</option>
                  <option value="T-4">T-4</option>
                  <option value="T-5">T-5</option>
                  <option value="T-6">T-6</option>
                  <option value="CPT">CPT</option>
                </>
              ) : (
                <>
                  {[...Array(55).keys()].map(i => (
                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                  ))}
                  <option value="CPD">CPD</option>
                  <option value="IAT">IAT</option>
                  <option value="HUB">HUB</option>
                  <option value="FB-1">FB-1</option>
                  <option value="FB-2">FB-2</option>
                  <option value="FB-3">FB-3</option>
                  <option value="FB-4">FB-4</option>
                  <option value="FB-5">FB-5</option>
                  <option value="GA-1">GA-1</option>
                  <option value="GA-2">GA-2</option>
                  <option value="GA-3">GA-3</option>
                  <option value="GA-4">GA-4</option>
                  <option value="GA-5">GA-5</option>
                  <option value="IG-1">IG-1</option>
                  <option value="IG-2">IG-2</option>
                  <option value="IG-3">IG-3</option>
                  <option value="TK-1">TK-1</option>
                  <option value="TK-2">TK-2</option>
                  <option value="TK-3">TK-3</option>
                  <option value="YT-1">YT-1</option>
                  <option value="YT-2">YT-2</option>
                  <option value="YT-3">YT-3</option>
                </>
              )
            )}
          </select>
        </div>
        <div>
          <label>Access Code</label>
          <input name="accessCode" value={formData.accessCode} onChange={handleChange} />
        </div>
        <div>
          <label>Name</label>
          <input name="name" value={formData.name} onChange={handleChange} />
        </div>
        <div>
          <label>Access Pin Code</label>
          <input name="accessPinCode" type="number" value={formData.accessPinCode} onChange={handleChange} readOnly />
        </div>
        <div>
          <label>Phone</label>
          <input name="phone" value={formData.phone} onChange={handleChange} />
        </div>
        <div>
          <label>Phone Suffix</label>
          <input name="phoneSuffix" value={formData.phoneSuffix} onChange={handleChange} />
        </div>
        <div>
          <label>Start Time</label>
          <input
            name="startTime"
            value={formData.startTime}
            onChange={handleChange}
            placeholder="HH:MM AM/PM"
          />
        </div>
        <div>
          <label>Date of Birth</label>
          <input name="dob" type="date" value={formData.dob} onChange={handleChange} />
        </div>
        <div>
          <label>Duration</label>
          <input
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            placeholder="HH:MM:SS"
          />
        </div>
        <div>
          <label>Enrolled</label>
          <input name="enrolled" type="checkbox" checked={formData.enrolled} onChange={handleChange} />
        </div>
        <div>
          <label>Notes</label>
          <textarea name="notes" value={formData.notes} onChange={handleChange} />
        </div>
        <div>
          <label>Lead</label>
          <input name="lead" type="checkbox" checked={formData.lead} onChange={handleChange} />
        </div>
        <div>
          <label>Enrolled Amount</label>
          <input name="enrolledAmount" value={formData.enrolledAmount} onChange={handleChange} />
        </div>
        <div>
          <label>Not Enough Debt</label>
          <input name="notEnoughDebt" type="checkbox" checked={formData.notEnoughDebt} onChange={handleChange} />
        </div>
        <div>
          <label>Email</label>
          <input name="email" type="email" value={formData.email} onChange={handleChange} />
        </div>
        <div>
          <label>SSN</label>
          <input name="ssn" value={formData.ssn} onChange={handleChange} />
        </div>
        {/* <div>
          <label>Credit Card Number</label>
          <input name="ccn" value={formData.ccn} onChange={handleChange} />
        </div> */}
        {/* <div>
          <label>Expiration Date</label>
          <input name="expirationDate" value={formData.expirationDate} onChange={handleChange} />
        </div> */}
        {/* <div>
          <label>CVV</label>
          <input name="cvv" value={formData.cvv} onChange={handleChange} />
        </div> */}


        {userDepartment !== 'debt' && (
          <>
            <div>
              <label>State Liability</label>
              <input name="stateLiability" value={formData.stateLiability} onChange={handleChange} />
            </div>
            <div>
              <label>Federal Liability</label>
              <input name="federalLiability" value={formData.federalLiability} onChange={handleChange} />
            </div>
          </>
        )}

        <div className="button-container">
          <button type="submit">Save</button>
          <button type="button" onClick={onClose}>Cancel</button>
          <button type="button" onClick={handleCreateLink}>Send Text Verification</button>
          <button type="button" onClick={handleSendEmail}>Send Email Verification</button>
        </div>
      </form>
    </Modal>
  );
}

export default EditModal;
